import { useMemo } from 'react';
import { useFullPartTypes } from 'hooks/partType';
import { LegacyJob, PartTypeList, UniversalPartType } from 'models';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { SearchSequenceType } from 'types/search';
import { formatPartTypesQuery } from 'utils';
import type { SearchQuery } from 'features/searchForm';

const getListTitle = (smsName: string | null, poNumber: string | null) => {
  if (smsName) {
    return poNumber ? `${smsName} RO #${poNumber}` : `${smsName} quote`;
  }

  return poNumber ? `RO #${poNumber}` : '';
};

export const usePartTypeSearchEntryFromSearchParams = (searchParams: SearchQuery) => {
  const partTextId = 'part_text_id' in searchParams ? searchParams.part_text_id : '';
  const partTypeIds = 'part_types' in searchParams ? (searchParams.part_types?.split(',') ?? []) : [];
  const partText = 'part_text' in searchParams ? searchParams.part_text : '';
  const attributes = useMemo(() => ('attributes' in searchParams ? searchParams.attributes : {}), [searchParams]);

  const ids = partTextId.length ? [partTextId] : formatPartTypesQuery(partTypeIds);

  const { smsName, poNumber, isLoaded, punchoutEnabled } = usePunchoutInfo();

  const partTypeListTitle = getListTitle(smsName, poNumber);

  const type = punchoutEnabled ? SearchSequenceType.PunchoutPartTypes : SearchSequenceType.PartTypes;

  const shouldLoad = !LegacyJob.isLegacyJobId(partTextId) && ids.length > 0;
  const { partTypes, isSuccess: isPartTypeSuccess } = useFullPartTypes({ ids, alias: partText }, { skip: !shouldLoad });

  const partType = partTypes[0] ?? null;

  const searchEntry = useMemo(() => {
    if (ids.length > 1) {
      return new PartTypeList(partTypes, partTypeListTitle, type);
    }

    if (partType instanceof UniversalPartType) {
      return partType.setSelectedAttributes(attributes ?? {});
    }

    return partType;
  }, [attributes, ids.length, partType, partTypeListTitle, partTypes, type]);

  const isSuccess = isPartTypeSuccess && isLoaded;

  return {
    searchEntry,
    partType,
    isSuccess,
    isReady: shouldLoad ? isSuccess : true,
  };
};
