import { keys } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetVehicle } from 'entities/vehicle';
import { KeywordSearch, SearchUrl } from 'features/searchForm';
import { isSearchSequenceCreator } from 'features/searchSequence';
import { useLegacyJobSearchEntryFromSearchParams, usePartTypeSearchEntryFromSearchParams } from 'hooks/search';
import { PartNumber, TireConfiguration } from 'models';
import { isPlainObject } from 'shared/lib/object';
import { decodeSearchParams } from 'utils';
import type { SearchQuery, PartTypeQuery } from 'features/searchForm';
import type { CheckedFilters } from 'types/search';

const retrieveCheckedFilters = (searchParams: SearchQuery) =>
  keys(searchParams).reduce((memo: Partial<CheckedFilters>, key) => {
    const checkedValues = searchParams[key] ?? [];

    if ((key as keyof PartTypeQuery) === 'attributes' && isPlainObject(checkedValues)) {
      return keys(checkedValues).reduce(
        (result, name) => ({
          ...result,
          [`attributes.${name}`]: 'length' in checkedValues ? [] : checkedValues[name],
        }),
        memo
      );
    }

    if (Array.isArray(checkedValues)) {
      return { ...memo, [key]: checkedValues };
    }

    return memo;
  }, {});

const parseSearchUrlParams = (search: string) => {
  const searchParams = decodeSearchParams<SearchQuery>(search);

  const { vehicle, vin, state, plate } = searchParams;

  const checkedFilters = retrieveCheckedFilters(searchParams);

  return {
    allParams: searchParams,
    checkedFilters,
    vehicleParams:
      vehicle || vin || (state && plate)
        ? {
            id: vehicle,
            vin,
            plate: state && plate ? { state, plate } : undefined,
          }
        : {},
  };
};

const createPartNumberEntry = (searchParams: SearchQuery) => {
  if (!('partnumber' in searchParams) || !searchParams.partnumber) {
    return null;
  }

  return new PartNumber({
    id: searchParams.partnumber,
    partNumber: searchParams.partnumber,
    brandName: searchParams.manufacturers?.[0] ?? null,
    searchType: searchParams.type,
  });
};

const createTireConfiguration = (searchParams: SearchQuery) => {
  if (!('tire_sizes' in searchParams)) {
    return null;
  }

  if (!searchParams.tire_sizes) {
    return null;
  }

  return TireConfiguration.createFromString(searchParams.tire_sizes);
};

const createKeywordSearch = (searchParams: SearchQuery) => {
  if (!('part_text' in searchParams) || 'part_text_id' in searchParams) {
    return null;
  }

  return new KeywordSearch(searchParams.part_text);
};

const useCreateSearchEntryFromSearchQuery = (searchQuery: SearchQuery) => {
  const { searchEntry: legacyJobSearchEntry, isReady: isLegacyJobReady } = useLegacyJobSearchEntryFromSearchParams(
    'part_text_id' in searchQuery ? searchQuery.part_text_id : undefined,
    'part_text' in searchQuery ? searchQuery.part_text : undefined
  );

  const { searchEntry: partTypeEntry, isReady: isPartTypeReady } = usePartTypeSearchEntryFromSearchParams(searchQuery);

  const searchEntry = useMemo(() => {
    if (!isLegacyJobReady) {
      return null;
    }

    return (
      createPartNumberEntry(searchQuery) ||
      createTireConfiguration(searchQuery) ||
      createKeywordSearch(searchQuery) ||
      partTypeEntry ||
      legacyJobSearchEntry
    );
  }, [searchQuery, isLegacyJobReady, legacyJobSearchEntry, partTypeEntry]);

  return useMemo(
    () => ({ searchEntry, isReady: isLegacyJobReady && isPartTypeReady }),
    [isLegacyJobReady, isPartTypeReady, searchEntry]
  );
};

type Result = {
  searchRequest: SearchUrl | null;
  isReady: boolean;
  isVehicleReady: boolean;
  isSearchEntryReady: boolean;
};

/**
 * (!) Should be called only once in SearchBarProvider
 *
 * @param search - URL search query string
 */
export const useSearchRequest = (search: string): Result => {
  const { allParams, checkedFilters, vehicleParams } = useMemo(() => parseSearchUrlParams(search), [search]);

  const { vehicle, isReady: isVehicleReady } = useGetVehicle(vehicleParams);
  const { searchEntry, isReady: isSearchEntryReady } = useCreateSearchEntryFromSearchQuery(allParams);

  const searchRequest = useMemo(() => {
    if (!isVehicleReady || !searchEntry) {
      return null;
    }

    if (isSearchSequenceCreator(searchEntry)) {
      return null;
    }

    const url = new SearchUrl(vehicle, searchEntry);

    return url
      .setCheckedFilters(checkedFilters)
      .setIsDealerSearch(allParams.dealers === '1')
      .setSelectedSupplierAccountId(allParams.selected_distributor ?? null);
  }, [allParams.dealers, allParams.selected_distributor, checkedFilters, isVehicleReady, searchEntry, vehicle]);

  return useMemo(
    () => ({ searchRequest, isReady: isVehicleReady && isSearchEntryReady, isVehicleReady, isSearchEntryReady }),
    [isSearchEntryReady, isVehicleReady, searchRequest]
  );
};
