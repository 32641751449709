import { useToggle } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { SearchTabs, useStartSearch } from 'features/searchForm';
import { useSearchParams } from 'hooks/search';
import { SearchTypes } from 'types/search';
import { useSearchRequest } from 'widgets/searchBar/context/useSearchRequest';
import { SearchBarContextProvider } from './SearchBarContext';
import { useSearchBarState } from './useSearchBarState';
import { useSearchType } from './useSearchBarState/useSearchType';
import { useSelectedTab } from './useSearchBarState/useSelectedTab';
import type { Vehicle } from 'entities/vehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { FunctionComponent, PropsWithChildren } from 'react';

export const SearchBarProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { search } = useAppLocation();

  const { searchRequest } = useSearchRequest(search);

  const {
    searchEntry: defaultSearchEntry,
    vehicle: defaultVehicle,
    type: defaultSearchType,
    selectedSupplierId,
    isDealersSearch,
  } = useSearchParams();

  const [searchType, changeSearchType] = useSearchType(defaultSearchType);
  const [entryPointsTab, changeEntryPointsTab] = useSelectedTab({
    searchType,
    onTabChange: (tab: SearchTabs) => {
      tab === SearchTabs.TIRES ? changeSearchType(SearchTypes.TIRES) : changeSearchType(SearchTypes.PARTS);
    },
  });
  const { isToggle: isSelectVehicleOpen, turnOn: openVehicleSelector, turnOff: closeVehicleSelector } = useToggle();

  const startSearch = useStartSearch({ onVehicleRequired: openVehicleSelector });

  const handleSearchBarStateChange = useCallback(
    (entry: SearchEntryPointInterface | null, vehicle: Vehicle | null) => {
      const preservedQueryParams = {
        selected_distributor: selectedSupplierId ?? undefined,
        dealers: isDealersSearch ? '1' : undefined,
      };

      startSearch(entry, vehicle, preservedQueryParams);
    },
    [isDealersSearch, selectedSupplierId, startSearch]
  );

  const { searchEntry, vehicle, changeSearchEntry, changeVehicle, resetSearch } = useSearchBarState({
    searchEntry: defaultSearchEntry,
    vehicle: defaultVehicle,
    onChange: handleSearchBarStateChange,
  });

  const value = useMemo(
    () => ({
      isSelectVehicleOpen,
      openVehicleSelector,
      closeVehicleSelector,

      currentSearchRequest: searchRequest,
      changeEntryPointsTab,
      changeSearchEntry,
      changeSearchType,
      changeVehicle,
      entryPointsTab,
      searchEntry,
      searchFormType: searchType,
      startSearch,
      vehicle,
      resetSearch,
    }),
    [
      isSelectVehicleOpen,
      openVehicleSelector,
      closeVehicleSelector,
      searchRequest,
      changeEntryPointsTab,
      changeSearchEntry,
      changeSearchType,
      changeVehicle,
      entryPointsTab,
      searchEntry,
      searchType,
      startSearch,
      vehicle,
      resetSearch,
    ]
  );

  return <SearchBarContextProvider value={value}>{children}</SearchBarContextProvider>;
};
