import { useMemo } from 'react';
import { createPartTypesFromQuery } from 'factories';
import { LegacyJob } from 'models';
import { useGetPartTypeGroup } from 'store/queries/partTypes/useGetPartTypeGroup';

export const useLegacyJobSearchEntryFromSearchParams = (partTextId: string = '', partText?: string) => {
  const shouldLoad = LegacyJob.isLegacyJobId(partTextId);

  const { partTypeGroup, isSuccess, isFetching } = useGetPartTypeGroup({
    id: LegacyJob.formatId(partTextId),
    skip: !shouldLoad,
  });

  const searchEntry = useMemo(() => {
    if (LegacyJob.isLegacyJobId(partTextId) && partText && partTextId) {
      return new LegacyJob({
        id: partTextId,
        name: partText,
        aliases: partTypeGroup?.aliases ?? [],
        partTypes: createPartTypesFromQuery(partTypeGroup?.partTypes ?? []),
      });
    }

    return null;
  }, [partTextId, partText, partTypeGroup?.aliases, partTypeGroup?.partTypes]);

  return { searchEntry, isSuccess: isSuccess && !isFetching, isReady: shouldLoad ? isSuccess : true };
};
